#aboutme {
    padding: 100px 0 50px;
    overflow: hidden;
}

#aboutme-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 32px;
}

#aboutme-left {
    width: 46%;
}

#desc-text {
    margin: 8px 0;
}

#aboutme-contact {
    margin-top: 1em;
    display: flex;
    justify-content: flex-start;
    text-align: center;
}

#contact-button-a {
    text-decoration: none;
}

#contact-button {
    padding: 12px;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 5px 20px -15px #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

#contact-text {
    margin-left: 10px; 
}

#aboutme-right {
    width: 46%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#skills-container {
    margin-top: 0.5em;
}

.skill-box {
    background-color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 16px;
    box-shadow: 0 5px 20px -15px #000000;
}

#education-list {
    list-style-type: none;
}

.education-li {
    background: url("../images/icons/cube-light.png") no-repeat;
    background-size: 20px;
    padding-left: 32px;
    margin: 8px 0;
}

/* animations */
.aboutme-header-0 {
    opacity: 0;
    filter: blur(1px);
}

.aboutme-header-1 {
    opacity: 1;
    filter: blur(0);
    transition: all 1s;
}

.aboutme-left-0 {
    opacity: 0;
    transform: translateX(-100%);
}

.aboutme-left-1 {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s;
    transition-delay: 100ms;
}

.aboutme-right-0 {
    opacity: 0;
    transform: translateX(100%);
}

.aboutme-right-1 {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s;
}

.skill-box:nth-child(2) {
    transition-delay: 20ms;
}

.skill-box:nth-child(3) {
    transition-delay: 40ms;
}

/* mobile view */
@media (max-width: 1000px) {
    #aboutme-container {
        flex-direction: column;
    }

    #aboutme-left {
        width: 100%;
        margin-bottom: 10%;
    }

    #aboutme-right {
        width: 100%;
    }

    #skills-container {
        margin-bottom: 10%;
    }
}