#contact {
    padding: 50px 0 0;
    overflow: hidden;
}

#contact-container {
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -5px 20px -15px #000000;
    padding: 50px 0;
}

#get-in-touch {
    padding: 16px 0;
}

#contact-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact-item {
    padding-top: 16px;
    display: flex;
    align-items: center;
}

.contact-text {
    padding-left: 4px;
}

/* animations */
.contact-0 {
    opacity: 0;
    transform: translateY(100%);
}

.contact-1 {
    opacity: 1;
    transform: translateY(0);
    transition: all 1s;
}

/* mobile view */
@media (max-width: 1000px) {
    #contact-container .section-header {
        width: 80%;
    }
}