#projects {
    padding: 50px 0;
    overflow: hidden;
}

#project-list {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-card {
    margin-bottom: 128px;
    width: 60vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gray);
    padding: 32px;
    border-radius: 25px;
    box-shadow: 0 5px 20px -15px #000000;
}

.project-img {
    width: 50%;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 5px 20px -15px #000000;
}

.project-text {
    width: 48%;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.project-desc {
    width: 80%;
    background-color: var(--secondary-color);
    border-radius: 8px;
    padding: 1em;
    margin: 1em 0;
    box-shadow: 0 5px 20px -15px #000000;
}

.desc-right {
    margin-left: auto;
}

.desc-left {
    margin-right: auto;
}

.project-links {
    padding-top: 1em;
}

#view-more {
    display: flex;
    justify-content: center;
    align-items: center;
}

#view-more-button {
    padding: 1em;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 5px 20px -15px #000000;
}

/* animations */
.projects-header-0 {
    opacity: 0;
    filter: blur(1px);
}

.projects-header-1 {
    opacity: 1;
    filter: blur(0);
    transition: all 1s;
}

.projects-left-0 {
    opacity: 0;
    transform: translateX(-100%);
}

.projects-left-1 {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s;
}

.projects-right-0 {
    opacity: 0;
    transform: translateX(40%);
}

.projects-right-1 {
    opacity: 1;
    transform: translateX(0);
    transition: all 1s;
}

/* mobile view */
@media (max-width: 1000px) {
    .project-card{
        width: 90%;
        flex-direction: column;
    }
    
    .project-img {
        z-index: -10;
        width: 100%;
        margin: 10% 0;
    }

    .project-text {
        width: 100%;
    }
}