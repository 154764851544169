#certifications {
    padding: 50px 0;
}

#certification-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 352px);
    justify-content: center;
    gap: 32px;
    padding-top: 32px;
}

.certification-card {
    padding: 16px;
    background-color: var(--gray);
    border-radius: 12px;
    box-shadow: 0 5px 20px -15px #000000;
    text-align: left;
}

.certification-card img {
    border-radius: 8px;
    width: 320px;
    margin-bottom: 8px;
}

/* animations */
.certifications-header-0 {
    opacity: 0;
    filter: blur(1px);
}

.certifications-header-1 {
    opacity: 1;
    filter: blur(0);
    transition: all 1s;
}

.certification-0 {
    opacity: 0;
    transform: translateY(100%);
}

.certification-1 {
    opacity: 1;
    transform: translateY(0);
    transition: all 1s;
}