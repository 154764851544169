#navbar {
    z-index: 10;
    position: fixed;
    width: 100%;
    background-color: var(--secondary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: top 0.3s;
}

#nav-left {
    padding: 12px 32px;
}

#nav-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

#nav-text {
    padding-left: 10px;
    font-size: 20px;
}

#nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-link {
    list-style: none;
    padding: 0 16px;
}

#nav-right {
    padding: 12px 32px;
}

.resume {
    display: flex;
    justify-content: flex-start;
    text-align: center;
}

.resume-button-a {
    text-decoration: none;
}

.resume-button {
    padding: 8px 12px;
    background-color: var(--primary-color);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 20px -15px #000000;
}

/* mobile view */
#hamburger-icon {
    margin: auto 16px;
    display: none;
}

.bar {
    width: 35px;
    height: 3px;
    background-color: var(--white);
    margin: 6px 0;
    transition: 0.4s;
}

#mobile-menu {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 56px;
    left: 0;
    height: auto;
    width: 100%;
    padding: 16px 0;
    background-color: var(--secondary-color);
}

#mobile-menu li {
    list-style: none;
    margin: 16px 0;
}

/* mobile menu toggle */
.open #bar-1 {
    transform: rotate(-45deg) translate(-6px, 6px);
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
}

.open #bar-2 {
    opacity: 0;
}

.open #bar-3 {
    transform: rotate(45deg) translate(-6px, -6px);
    -webkit-transform: rotate(45deg) translate(-6px, -8
    6px);
}

.open #mobile-menu {
    visibility: visible;
}

/* animation */
.nav-list-0 {
    opacity: 0;
    transform: translateX(100%);
    transition: all 0.5s;
}

.nav-list-1 {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.5s;
}

/* mobile view */
@media (max-width: 1000px) {
    #nav-middle {
        display: none;
    }

    #nav-right {
        display: none;
    }

    #hamburger-icon {
        display: block
    }
}