#about-container {
    min-height: 100vh;
    background-color: var(--secondary-color);
    padding: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    border-radius: 0 0 8px 8px;
    box-shadow: 0 5px 20px -15px #000000;
}

#about-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    animation: slideInFromLeft 1s ease;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

#about-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* spinning cube + text overlay */
#text-canvas-overlay {
    height: 320px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cube {
    position: absolute;
}

#hi-overlay {
    position: absolute;
    z-index: 1;
    font-size: 48px;
    color: var(--primary-color);
}

/* description */
#about-desc {
    margin: 0 5% 0 20%;
}

#roles {
    height: 1.2em;
}

@keyframes blinking {
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
}
  
.line-blink {
    animation: blinking 0.7s  infinite;
    background-color: #ffffff;
    padding: 0 3px;
    margin: 0 5px;
}

#short-desc {
    margin: 0.5em 0;
}

/* links */
#about-links {
    display: flex;
    justify-content: left;
    align-items: center;
}

/* about me */
#about-right img {
    width: 90%;
    border-radius: 25px;
    box-shadow: 0 5px 20px -15px #000000;
    -webkit-animation: flip 1s ease;
    animation: flip 1s ease;
}

@-webkit-keyframes flip {
    0% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

@keyframes flip {
    0% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

/* mobile view */
@media (max-width: 1000px) {
    #about-container {
        flex-direction: column;
    }

    #about-desc {
        margin: 0 10% 10%;
    }

    #text-canvas-overlay {
        margin-top: 32px;
    }
}