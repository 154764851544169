#projectsfull {
    padding-top: 162px;
}

#projectsfull-list {
    width: 100%;
    border-spacing: 0;
    margin-top: 32px;
    padding-bottom: 100px;
}

#projectsfull-list tr {
    background-color: var(--gray);
}

#projectsfull-list th {
    padding: 16px 32px;
    color: var(--primary-color);
}

#projectsfull-list td {
    padding: 16px 32px;
}

.mobile-optional {
    display: table-cell;
}

.projectsfull-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* rounded table */
#projectsfull-list th:first-child{
    border-radius: 8px 0 0 0;
}

#projectsfull-list th:last-child{
    border-radius: 0 8px 0 0;
}

#projectsfull-list tr:last-child td:first-child {
    border-radius: 0 0 0 8px;
}

#projectsfull-list tr:last-child td:last-child {
    border-radius: 0 0 8px 0;
}

/* animations */
.projectsfull-header-0 {
    opacity: 0;
    filter: blur(1px);
}

.projectsfull-header-1 {
    opacity: 1;
    filter: blur(0);
    transition: all 1s;
}

.projectsfull-slide-0 {
    opacity: 0;
    transform: translateY(100%);
}

.projectsfull-slide-1 {
    opacity: 1;
    transform: translateY(0);
    transition: all 1s;
}

/* mobile view */
@media (max-width: 1000px) {
    .mobile-optional {
        display: none;
    }

    #projectsfull-list th {
        padding: 16px;
    }
    
    #projectsfull-list td {
        padding: 16px;
    }

    #projectsfull-list .icon {
        height: 24px;
    }
}