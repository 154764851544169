#achievements {
    padding: 50px 0;
}

#achievements-list {
    min-width: 60vw;
    margin: 32px auto 0;
}

.achievement-card {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 32px;
    padding: 8px;
    border-radius: 15px;
    background-color: var(--secondary-color);
    text-align: center;
    box-shadow: 0 5px 20px -15px #000000;
}

.swiper-wrapper {
    align-items: center;
}

/* animations */
.achievements-0 {
    opacity: 0;
    filter: blur(1px);
}

.achievements-1 {
    opacity: 1;
    filter: blur(0);
    transition: all 1s;
}