/* GLOBAL */
* {
    margin: 0;
    padding: 0;
}

:root {
    --primary-color: #f1beb0;
    --secondary-color: #7cc1ac;
    --black: #263238;
    --gray: #364248;
    --white: #ffffff;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    color: var(--white);
    scroll-behavior: smooth;
}

.root {
    height: 100%;
}

html {
    height: 100%;
}

body {
    height: 100%;
    background-color: var(--black);
}

h1 {
    color: var(--primary-color);
    font-size: 40px;
    font-weight: bold;
}

h2 {
    font-size: 24px;
    font-weight: bold;
}

p {
    font-size: 18px;
}

a {
    font-size: 18px;
    text-decoration: none;
    color: inherit;
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.remark {
    font-size: 14px;
    font-style: italic;
}

.a-icon {
    margin: 0 1em 0 0;
}

.icon {
    height: 32px;
    width: auto;
}

.duration {
    float: right;
}

.content-area {
    width: 60%;
    margin: 0 auto;
}

.section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0;
    width: 50%;
    margin: 0 auto;
}

.section-cube {
    height: 32px;
    width: auto;
    padding: 1em 6px;
}

.center {
    text-align: center;
}

.primary {
    color: var(--primary-color);
}

.secondary {
    color: var(--secondary-color);
}

.white {
    color: var(--white);
}

.black {
    color: var(--black);
}

.bold {
    font-weight: bold;
}

/* mobile view */
@media (max-width: 1000px) {
    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 20px;
    }

    p {
        font-size: 16px;
    }

    .remark {
        font-size: 12px;
    }

    .content-area {
        width: 90%;
    }

    .section-header {
        width: 100%;
    }
}